import * as Sentry from "@sentry/browser";
import { Integration } from "@sentry/types";
import { KeycloakConfig, KeycloakInitOptions } from "keycloak-js";

/**
 * This interface is designed to abstract all configuration and settings
 * and provide documentation for their source and intention.
 */
export interface IAppConfig {
	/**
	 * True if running on a local machine
	 */
	isDevelopment: boolean;
	/**
	 * Configuration for Sentry. Used for monitoring and logging.
	 */
	keycloak: {
		/**
		 * Options for the keycloak server
		 */
		config: KeycloakConfig;
		/**
		 * The initialization configuration for Keycloak
		 */
		initOptions: KeycloakInitOptions;
		/**
		 *
		 */
		minValidity: number;
	};
	sentry: {
		/**
		 * whether to enable sentry
		 */
		enableSentry: boolean;
		/**
		 * Tells sentry where to send errors. This can be found in the sentry portal for the camunda-react-ui project.
		 */
		dsn: string;
		/**
		 * The environment we are running in i.e. Prod, UAT or Dev
		 */
		environment: string;
		/**
		 * The sentry integration we are using
		 */
		integrations: Integration[];
		/**
		 * This is a value from 0 to 1.0 that reflects the percentage of errors that are sent to Sentry
		 */
		tracesSampleRate: number;
		/**
		 * Max lines of contextual data
		 */
		normalizeDepth: number;
		/**
		 * Max number of breadcrumbs to capture
		 */
		maxBreadcrumbs: number;
	};
	API: {
		/**
		 * The base url for the API. Note: includes '/' at the end
		 */
		baseURL: string;
		/**
		 * Camunda Process Controller API. Note: includes '/' at the end
		 */
		camundaProcessControllerURL: string;
	};
}

export const appConfig: IAppConfig = {
	isDevelopment: process.env.REACT_APP_ENVIRONMENT === "dev",
	keycloak: {
		minValidity: 2,
		config: {
			url: process.env.REACT_APP_KEYCLOAK_URL as string,
			realm: process.env.REACT_APP_KEYCLOAK_REALM as string,
			clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID as string,
		},
		initOptions: {
			/**
			 * This option has some complexity and requires some explanation.
			 *
			 * Option 1: "login-required"
			 * --------------------------
			 * Will authenticate the client if the user is logged-in
			 * to Keycloak or display the login page or not.
			 *
			 * Option 2: "check-sso"
			 * ---------------------
			 * Will only authenticate the client if the user is already
			 * logged in, if the user is not logged-in the browser will be
			 * redirected back to the application and remain unauthenticated.
			 *
			 * Decision
			 * ----------
			 * I went for "login-required" because I didn't want to the complexity
			 * of a manual redirect to the login page.
			 *
			 * Note that Newton uses "check-sso" and manually redirects.
			 *
			 * I don't feel this is right for us.
			 *
			 * Happy to discuss or change later.
			 *
			 * docs here : https://www.keycloak.org/docs/latest/securing_apps/#_javascript_adapter
			 */
			onLoad: "login-required",
			adapter: "default",
			enableLogging: true,
		},
	},
	sentry: {
		enableSentry: !!process.env.REACT_APP_ENABLE_SENTRY,
		dsn: process.env.REACT_APP_SENTRY_DSN as string,
		environment: process.env.REACT_APP_ENVIRONMENT as string,
		integrations: [Sentry.browserTracingIntegration()],
		tracesSampleRate: Number(process.env.REACT_APP_SENTRY_TRACING_SAMPLE_RATE),
		normalizeDepth: 10,
		maxBreadcrumbs: 20,
	},
	API: {
		baseURL: process.env.REACT_APP_API_URL as string,
		camundaProcessControllerURL: process.env
			.REACT_APP_CAMUNDA_PROCESS_CONTROLLER_URL as string,
	},
};
