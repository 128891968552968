import React from "react";

import ReactDOM from "react-dom/client";
import { ThemeProvider } from "@mui/material/styles";

import { App } from "./App";
import reportWebVitals from "./reportWebVitals";
import { theme } from "./styles/theme";
import { handleError } from "./utilities";

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement,
);

(async () => {
	try {
		root.render(
			<React.StrictMode>
				<ThemeProvider theme={theme}>
					<App />
				</ThemeProvider>
			</React.StrictMode>,
		);
	} catch (error) {
		handleError(error);
		root.render(
			<React.StrictMode>
				<ThemeProvider theme={theme}>
					<App />
				</ThemeProvider>
			</React.StrictMode>,
		);
	}
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
