import { useContext, useState } from "react";

import ReplayOutlinedIcon from "@mui/icons-material/ReplayOutlined";
import {
	Box,
	CircularProgress,
	Grid,
	IconButton,
	Typography,
} from "@mui/material";
import { useQuery } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";

import { getInboundFlowTest } from "../../api/camunda/camunda-process-controller-api";
import { AuthContext } from "../../auth";
import {
	CamundaProcessDetails,
	CamundaProcessFlowLog,
} from "../../components/camunda-process";
import { CamundaProcessBpmnDigram } from "../../components/camunda-process/camunda-process-bpmn-diagram";
import {
	InboundFlowTestIfToPubDiff,
	InboundFlowTestPUBMessage,
	InboundFlowTestResult,
} from "../../components/inbound-flows-testing";
import { InboundFlowTestIFMessage } from "../../components/inbound-flows-testing/inbound-flow-test-if-message";
import {
	InboundFlowTestingProcessState,
	InboundFlowTestPagedResponse,
	InboundFlowTestResponse,
} from "../../models/camunda";
import { AlertContext, handleError } from "../../utilities";

const isJsonStringParsable = (data?: string) => {
	try {
		if (data) {
			JSON.parse(data);
			return true;
		} else {
			return false;
		}
	} catch (e) {
		return false;
	}
};

export const InboundFlowTestingProcess: React.FC = () => {
	const navigate = useNavigate();

	const authContext = useContext(AuthContext);

	const location = useLocation();
	const { pathname } = location;
	const processId: number = Number(pathname.split("/").pop());

	const { setTalosAlert } = useContext(AlertContext);

	const [showBpmnSection, setShowBpmnSection] = useState<boolean>(false);

	const [showFlowLogSection, setShowFlowLogSection] = useState<boolean>(false);

	const [showTestResultSection, setShowTestResultSection] =
		useState<boolean>(false);

	const [showIFMessageSection, setShowIFMessageSection] =
		useState<boolean>(false);

	const [showPubMessageSection, setShowPubMessageSection] =
		useState<boolean>(false);

	const [showMessageDiffSection, setShowMessageDiffSection] =
		useState<boolean>(false);

	const { data, isLoading, refetch } = useQuery(
		["inbound-flow-tests", authContext, location],
		() => getInboundFlowTest(authContext, processId),
		{
			onSuccess: (data: InboundFlowTestResponse) => {
				const currentEvent = data.currentEvent ? data.currentEvent : " ";
				const isProcessCompleted = data.state == "COMPLETED";
				const isBPMNErrorOccurred =
					currentEvent ==
						InboundFlowTestingProcessState.NotImplemented.valueOf() ||
					currentEvent == InboundFlowTestingProcessState.Timeout.valueOf();

				const isBpmnAvailable = data.bpmn != undefined;
				const isFlowLogAvailable = data.flowLog.length != 0;
				const isIFMessageAvailable = data.variables.ifMessage != undefined;
				const isPubMessageAvailable = data.variables.pubMessage != undefined;

				const isComparatorResultAvailable = data.variables.result != undefined;

				setShowBpmnSection(isBpmnAvailable);
				setShowFlowLogSection(isFlowLogAvailable);
				setShowTestResultSection(
					isProcessCompleted &&
						isComparatorResultAvailable &&
						isJsonStringParsable(data.variables.result),
				);
				setShowIFMessageSection(
					isIFMessageAvailable &&
						isJsonStringParsable(data.variables.ifMessage),
				);
				setShowPubMessageSection(
					isPubMessageAvailable &&
						isJsonStringParsable(data.variables.pubMessage),
				);
				setShowMessageDiffSection(
					isComparatorResultAvailable &&
						isJsonStringParsable(data.variables.result) &&
						!isBPMNErrorOccurred,
				);
			},
			select: (data: InboundFlowTestPagedResponse) => {
				if (data.items.length == 0) {
					navigate("/inbound-flow-testing-processes");
				}
				return data.items[0];
			},
			onError: (e: Error) => {
				const errorId = handleError(e);
				setTalosAlert({
					message: `Something went wrong while fetching Inbound Flow Test, please try again later or contact IOPS Support. Ticket ID: ${errorId}`,
					severity: "error",
				});
			},
		},
	);

	return (
		<Box data-cy="inbound-flow-test-panel">
			<Typography variant="h2" sx={{ mt: 0, mb: 1, textAlign: "center" }}>
				Inbound Flow Testing Process
			</Typography>
			{isLoading || !data ? (
				<Box>
					<Typography minWidth={900} sx={{ mt: 8, mb: 1, textAlign: "center" }}>
						<CircularProgress />
					</Typography>
				</Box>
			) : (
				<Box>
					<Grid
						container
						direction="row"
						justifyContent="flex-end"
						alignItems="center"
					>
						<IconButton
							aria-label="previous"
							onClick={() => {
								refetch();
							}}
						>
							<ReplayOutlinedIcon />
						</IconButton>
					</Grid>
					<CamundaProcessDetails process={data} />
					{showBpmnSection && <CamundaProcessBpmnDigram bpmn={data.bpmn} />}
					{showFlowLogSection && <CamundaProcessFlowLog process={data} />}
					{showTestResultSection && <InboundFlowTestResult process={data} />}
					{showIFMessageSection && (
						<InboundFlowTestIFMessage message={data.variables.ifMessage!!} />
					)}
					{showPubMessageSection && (
						<InboundFlowTestPUBMessage message={data.variables.pubMessage!!} />
					)}
					{showMessageDiffSection && (
						<InboundFlowTestIfToPubDiff
							comparatorResult={data.variables.result!!}
						/>
					)}
				</Box>
			)}
		</Box>
	);
};
