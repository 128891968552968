import React from "react";

import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import { Home, Layout, NotFound } from "../pages";
import { ProtectedPage } from "./protected-route";
import { ROLES } from "../utilities";
// import { Tasklist } from "../pages/tasklist";
import { AddS0020Flow } from "../pages/mpan-relationship/add-s0020-flow";
import { AddInboundFlowTest } from "../pages/inbound-flows/add-inbound-flow-test";
import { InboundFlowTestingProcess } from "../pages/inbound-flows/inbound-flow-testing-process";
import { InboundFlowTestingProcesses } from "../pages/inbound-flows/inbound-flow-testing-processes";
import { MpanRelationshipProcesses } from "../pages/mpan-relationship/mpan-relationship-processes";
import { MpanRelationshipProcess } from "../pages/mpan-relationship/mpan-relationship-process";

export const AppRouter: React.FC = () => {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<Layout />}>
					<Route index element={<Home />} />
					<Route
						path="inbound-flow-testing-processes"
						element={
							<ProtectedPage role={ROLES.INBOUND_TESTING}>
								<InboundFlowTestingProcesses />
							</ProtectedPage>
						}
					/>
					<Route
						path="inbound-flow-testing-processes/:id"
						element={
							<ProtectedPage role={ROLES.INBOUND_TESTING}>
								<InboundFlowTestingProcess />
							</ProtectedPage>
						}
					/>
					<Route
						path="inbound-flow-testing-processes/start"
						element={
							<ProtectedPage role={ROLES.INBOUND_TESTING}>
								<AddInboundFlowTest />
							</ProtectedPage>
						}
					/>
					<Route
						path="mpan-relationship-processes/add"
						element={
							<ProtectedPage role={ROLES.S0020}>
								<AddS0020Flow />
							</ProtectedPage>
						}
					/>
					<Route
						path="mpan-relationship-processes"
						element={
							<ProtectedPage role={ROLES.S0020}>
								<MpanRelationshipProcesses />
							</ProtectedPage>
						}
					/>
					<Route
						path="mpan-relationship-processes/:id"
						element={
							<ProtectedPage role={ROLES.INBOUND_TESTING}>
								<MpanRelationshipProcess />
							</ProtectedPage>
						}
					/>
					<Route path="404" element={<NotFound />} />
				</Route>
				<Route path="*" element={<Navigate to="/404" />} />
			</Routes>
		</BrowserRouter>
	);
};
