import React from "react";

import {
	Box,
	FormControl,
	InputLabel,
	Select,
	SelectChangeEvent,
} from "@mui/material";
import { FormikProps } from "formik";

import { getValueFromForm } from "../../utilities";

interface DropDownProps<T> {
	fieldName: string;
	label: string;
	form: FormikProps<T>;
	menuItems: JSX.Element[];
	disabled?: boolean;
	postOnChange?: (event: SelectChangeEvent<any>, value: string) => void;
}

export const TalosDropDown = <T extends object>(
	props: DropDownProps<T>,
): JSX.Element => {
	const fieldValue = getValueFromForm(props.fieldName, props.form.values);
	const ID = `select-${props.fieldName}`;

	return (
		<Box className="form-row">
			<FormControl fullWidth>
				<InputLabel id={ID}>{props.label}</InputLabel>
				<Select
					disabled={props.disabled}
					data-cy={ID}
					labelId={ID}
					id={props.fieldName}
					value={fieldValue}
					label={props.label}
					onChange={(e) => {
						props.form.setFieldValue(props.fieldName, e.target.value);

						if (props.postOnChange) {
							props.postOnChange(e, e.target.value);
						}
					}}
				>
					{props.menuItems}
				</Select>
			</FormControl>
		</Box>
	);
};
