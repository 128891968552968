import React, { useEffect, useState } from "react";

export type Severity = "error" | "success" | "info";

export interface ITalosAlert {
	message: string;
	severity: Severity;
}

export const defaultAlertState: ITalosAlert = {
	message: "",
	severity: "info",
};

export interface IAlertContext {
	talosAlert: ITalosAlert;
	setTalosAlert: React.Dispatch<React.SetStateAction<ITalosAlert>>;
}

export const AlertContext = React.createContext<IAlertContext>({
	talosAlert: defaultAlertState,
	setTalosAlert: () => {},
});

export function useCountdown(seconds: number) {
	const [secondsLeft, setSecondsLeft] = useState(seconds);

	useEffect(() => {
		if (secondsLeft <= 0) return;

		const timeout = setTimeout(() => {
			setSecondsLeft(secondsLeft - 1);
		}, 1000);

		return () => clearTimeout(timeout);
	}, [secondsLeft]);

	function startCountdown() {
		setSecondsLeft(seconds);
	}
	return { secondsLeft, startCountdown };
}
