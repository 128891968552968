import { useContext, useState } from "react";

import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import ReplayOutlinedIcon from "@mui/icons-material/ReplayOutlined";
import {
	Box,
	CircularProgress,
	Divider,
	FormControl,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	SelectChangeEvent,
	Stack,
	Typography,
} from "@mui/material";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

import { getInboundFlowTests } from "../../api/camunda/camunda-process-controller-api";
import { AuthContext } from "../../auth";
import { InboundFlowTestingProcessesTable } from "../../components/inbound-flows-testing/inbound-flow-test-processes-table";
import { InboundFlowTestPagedResponse } from "../../models/camunda";
import { AlertContext, handleError } from "../../utilities";

export const InboundFlowTestingProcesses: React.FC = () => {
	const authContext = useContext(AuthContext);

	const navigate = useNavigate();

	const { setTalosAlert } = useContext(AlertContext);

	const [searchAfter, setSearchAfter] = useState<Array<Array<number>>>([]);
	const [showPreviousButton, setShowPreviousButton] = useState<boolean>(false);
	const [showNextButton, setShowNextButton] = useState<boolean>(true);
	const [pageSize, setPageSize] = useState<number>(5);

	const { data, isLoading, refetch } = useQuery(
		["inbound-flow-tests", authContext, pageSize],
		() =>
			getInboundFlowTests(
				authContext,
				pageSize,
				searchAfter[searchAfter.length - 1],
			),
		{
			onSuccess: (data: InboundFlowTestPagedResponse) => {
				if (searchAfter.length == 0) {
					setShowPreviousButton(false);
				} else {
					setShowPreviousButton(true);
				}

				if ((data && data.items.length == 0) || data.items.length < pageSize) {
					setShowNextButton(false);
				} else {
					setShowNextButton(true);
				}
			},
			onError: (e: Error) => {
				const errorId = handleError(e);
				setTalosAlert({
					message: `Something went wrong while fetching Inbound Flow Testing Processes, please try again later or contact IOPS Support. Ticket ID: ${errorId}`,
					severity: "error",
				});
			},
		},
	);

	const goToNextPage = () => {
		if (data) {
			let current = searchAfter;
			current.push(data.searchAfter);
			setSearchAfter(current);
			refetch();
		}
	};

	const goToPreviousPage = () => {
		if (data) {
			let current = searchAfter;
			current.pop();
			setSearchAfter(searchAfter);
			refetch();
		}
	};

	const refreshPage = () => {
		refetch();
	};

	//fixme: url is not being changed
	const createNewInboundTest = () => {
		navigate("/inbound-flow-testing-processes/start");
	};

	const changePageSize = (event: SelectChangeEvent<number>) => {
		setPageSize(event.target.value as number);
		setSearchAfter([]);
		refetch();
	};

	return (
		<Box data-cy="inbound-flow-tests-panel">
			<Typography variant="h2" sx={{ mt: 0, mb: 1, textAlign: "center" }}>
				Inbound Flow Testing Processes
			</Typography>
			{isLoading || !data ? (
				<Typography sx={{ mt: 8, mb: 1, textAlign: "center" }}>
					<CircularProgress />
				</Typography>
			) : (
				<>
					<Stack
						direction="row"
						spacing={2}
						alignItems="flex-end"
						justifyContent="space-between"
					>
						<Box>
							<IconButton aria-label="previous" onClick={createNewInboundTest}>
								<AddOutlinedIcon />
							</IconButton>
						</Box>
						<Box>
							<IconButton aria-label="previous" onClick={refreshPage}>
								<ReplayOutlinedIcon />
							</IconButton>
						</Box>
					</Stack>
					<Divider sx={{ p: 0 }} />
					<InboundFlowTestingProcessesTable response={data} />
					<Stack
						direction="row"
						spacing={2}
						alignItems="flex-end"
						justifyContent="space-between"
					>
						<FormControl
							variant="standard"
							sx={{ m: 1, minWidth: 100 }}
							size="small"
						>
							<InputLabel id="select-page-size">Page size</InputLabel>
							<Select
								name="page-size"
								labelId="page-size"
								id="page-size"
								label="Page size"
								defaultValue={pageSize}
								value={pageSize}
								onChange={changePageSize}
							>
								<MenuItem value={5}>5</MenuItem>
								<MenuItem value={10}>10</MenuItem>
								<MenuItem value={20}>20</MenuItem>
								<MenuItem value={50}>50</MenuItem>
								<MenuItem value={100}>100</MenuItem>
							</Select>
						</FormControl>
						<Box sx={{ mt: 1 }} display="flex" justifyContent="flex-end">
							{showPreviousButton && (
								<IconButton aria-label="previous" onClick={goToPreviousPage}>
									<KeyboardArrowLeftRoundedIcon />
								</IconButton>
							)}
							{showNextButton && (
								<IconButton aria-label="next" onClick={goToNextPage}>
									<KeyboardArrowRightRoundedIcon />
								</IconButton>
							)}
						</Box>
					</Stack>
				</>
			)}
		</Box>
	);
};
