import { Box, MenuItem, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import { UseMutationResult } from "react-query";

import {
	IS0020FlowFormValues,
	LinkedImportOrExportMpanS0020Request,
	MPAN_ACTION,
	RelatedMpanS0020Request,
	RELATIONSHIP_ACTION,
	RELATIONSHIP_TYPES,
	ReletionshipType,
} from "../../models/camunda/mpan-relationship-process";
import { TalosButton, TalosDropDown, TalosTextBox } from "../forms";
import { formValidationSchema } from "./add-d0020-flow-form.validation-schema";

interface IProps {
	s0020linkedImportOrExportMpanMutation: UseMutationResult<
		Boolean,
		Error,
		LinkedImportOrExportMpanS0020Request
	>;
	s0020relatedMpanMutation: UseMutationResult<
		Boolean,
		Error,
		RelatedMpanS0020Request
	>;
	formData: IS0020FlowFormValues;
	disablePrincipalMpan: boolean;
	disableRelationshipType: boolean;
}

export const AddS0020FlowForm: React.FC<IProps> = ({
	s0020linkedImportOrExportMpanMutation,
	s0020relatedMpanMutation,
	formData,
	disablePrincipalMpan,
	disableRelationshipType,
}) => {
	const handleOnSubmit = (values: IS0020FlowFormValues) => {
		if (values.relationshipType === ReletionshipType.L.valueOf()) {
			s0020linkedImportOrExportMpanMutation.mutate(
				mapFormToLinkedImportOrExportMpanValues(values),
			);
		}

		if (values.relationshipType === ReletionshipType.R.valueOf()) {
			s0020relatedMpanMutation.mutate(mapFormToRelatedMpanValues(values));
		}
	};

	return (
		<Formik
			enableReinitialize
			onSubmit={handleOnSubmit}
			initialValues={formData}
			validationSchema={formValidationSchema}
			validateOnChange={true}
		>
			{(form) => (
				<Form>
					<Box className="form-wrapper">
						<Box className="form-column">
							<TalosTextBox
								disabled={disablePrincipalMpan}
								fieldName="principalMpan"
								label="Principal MPAN*"
								form={form}
								sx={{ my: 1, width: "100%" }}
							/>
							<Box sx={{ my: 1, width: "100%" }}>
								<TalosDropDown
									disabled={disableRelationshipType}
									fieldName="relationshipType"
									label="Relationship Type*"
									form={form}
									menuItems={RELATIONSHIP_TYPES.map((wl, idx) => (
										<MenuItem key={`relationship_type_${idx}`} value={wl.value}>
											{wl.display}
										</MenuItem>
									))}
								/>
							</Box>
							<Box sx={{ my: 2, width: "100%" }}>
								<TalosDropDown
									fieldName="relationshipAction"
									label="Relationship Action*"
									form={form}
									menuItems={RELATIONSHIP_ACTION.map((wl, idx) => (
										<MenuItem
											key={`relationship_action_${idx}`}
											value={wl.value}
										>
											{wl.display}
										</MenuItem>
									))}
								/>
							</Box>
							{form.values.relationshipType === "L" && (
								<>
									<Typography variant="h3" sx={{ my: 2 }}>
										Linked Export MPAN
									</Typography>
									<TalosTextBox
										fieldName="exportMpan"
										label="Export MPAN*"
										form={form}
										sx={{ my: 1, width: "100%" }}
									/>
									<Box sx={{ my: 2, width: "100%" }}>
										<TalosDropDown
											fieldName="mpanAction"
											label="MPAN Action*"
											form={form}
											menuItems={MPAN_ACTION.map((wl, idx) => (
												<MenuItem key={`mpan_action_${idx}`} value={wl.value}>
													{wl.display}
												</MenuItem>
											))}
										/>
									</Box>
								</>
							)}
							{form.values.relationshipType === "R" && (
								<>
									<Typography variant="h3" sx={{ my: 2 }}>
										Secondary MPAN
									</Typography>
									<TalosTextBox
										fieldName="secondaryMpan"
										label="Secondary MPAN*"
										form={form}
										sx={{ my: 1, width: "100%" }}
									/>
									<Box sx={{ my: 2, width: "100%" }}>
										<TalosDropDown
											fieldName="mpanAction"
											label="MPAN Action*"
											form={form}
											menuItems={MPAN_ACTION.map((wl, idx) => (
												<MenuItem key={`mpan_action_${idx}`} value={wl.value}>
													{wl.display}
												</MenuItem>
											))}
										/>
									</Box>
								</>
							)}
							<TalosButton
								fieldName="form_submit"
								form={form}
								buttonText="Submit"
								loading={
									s0020linkedImportOrExportMpanMutation.isLoading ||
									s0020relatedMpanMutation.isLoading
								}
							/>
						</Box>
					</Box>
				</Form>
			)}
		</Formik>
	);
};

const mapFormToLinkedImportOrExportMpanValues = (
	values: IS0020FlowFormValues,
): LinkedImportOrExportMpanS0020Request => {
	return {
		principalMpan: values.principalMpan,
		relationshipType: values.relationshipType,
		relationshipAction: values.relationshipAction,
		linkedExportMpans: [
			{
				mpanAction: values.mpanAction,
				exportMpan: values.exportMpan!,
			},
		],
	};
};

const mapFormToRelatedMpanValues = (
	values: IS0020FlowFormValues,
): RelatedMpanS0020Request => {
	return {
		principalMpan: values.principalMpan,
		relationshipType: values.relationshipType,
		relationshipAction: values.relationshipAction,
		secondaryMpans: [
			{
				mpanAction: values.mpanAction,
				secondaryMpan: values.secondaryMpan!,
			},
		],
	};
};
