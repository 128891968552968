import React, { useContext } from "react";

import { Divider, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { useMutation, UseMutationResult } from "react-query";
import { useNavigate } from "react-router-dom";

import {
	addLinkedImportOrExportMpanS0020Request,
	addRelatedMpanS0020Request,
} from "../../api/camunda";
import { AuthContext } from "../../auth";
import { AddS0020FlowForm } from "../../components/mpan-relationship";
import {
	IS0020FlowFormValues,
	LinkedImportOrExportMpanS0020Request,
	RelatedMpanS0020Request,
} from "../../models/camunda";
import { AlertContext, handleError } from "../../utilities";

const initialValues: IS0020FlowFormValues = {
	principalMpan: "",
	relationshipType: "",
	relationshipAction: "",
	mpanAction: "",
};

export const AddS0020Flow: React.FC = () => {
	const authContext = useContext(AuthContext);

	const { setTalosAlert } = useContext(AlertContext);

	const navigate = useNavigate();

	const submitLinkedImportOrExportMpanS0020Request: UseMutationResult<
		Boolean,
		Error,
		LinkedImportOrExportMpanS0020Request
	> = useMutation(
		(values: LinkedImportOrExportMpanS0020Request) => {
			return addLinkedImportOrExportMpanS0020Request(authContext, values);
		},
		{
			onSuccess: () => {
				setTalosAlert({
					message:
						"LinkedImportOrExport MPAN Relationship Change has been received",
					severity: "success",
				});
				navigate("/mpan-relationship-processes");
			},
			onError: (e: Error) => {
				const errorId = handleError(e);

				setTalosAlert({
					message: `Something went wrong submitting LinkedImportOrExport MPAN Relationship Change form. Please contact IOPS Support, Ticket ID: ${errorId}`,
					severity: "error",
				});

				scrollTo(0, 0);
			},
		},
	);

	const submitRelatedMpanS0020Request: UseMutationResult<
		Boolean,
		Error,
		RelatedMpanS0020Request
	> = useMutation(
		(values: RelatedMpanS0020Request) => {
			return addRelatedMpanS0020Request(authContext, values);
		},
		{
			onSuccess: () => {
				setTalosAlert({
					message: "Related MPAN Relationship Change has been received",
					severity: "success",
				});
				navigate("/mpan-relationship-processes");
			},
			onError: (e: Error) => {
				const errorId = handleError(e);

				setTalosAlert({
					message: `Something went wrong submitting Related MPAN Relationship Change form. Please contact IOPS Support, Ticket ID: ${errorId}`,
					severity: "error",
				});

				scrollTo(0, 0);
			},
		},
	);

	return (
		<Box sx={{ width: "760px", margin: "0 auto;" }}>
			<Typography variant="h2" sx={{ mt: 0, mb: 1, textAlign: "center" }}>
				New Change MPAN Relationship Request
			</Typography>
			<Typography variant="body2">
				Fill in this form to send a new PUB-019 industry flow via an S0020
				integration flow to ESG to perform a change to MPAN Relationship.
				<br />
				This action can create or cahnge realted MPAN{"'"}s and link import and
				export MPANs.
			</Typography>
			<Typography variant="body2" sx={{ mt: 1 }}>
				* = Required
			</Typography>
			<Divider sx={{ my: 2 }} />
			<AddS0020FlowForm
				s0020linkedImportOrExportMpanMutation={
					submitLinkedImportOrExportMpanS0020Request
				}
				s0020relatedMpanMutation={submitRelatedMpanS0020Request}
				formData={initialValues}
				disablePrincipalMpan={false}
				disableRelationshipType={false}
			></AddS0020FlowForm>
		</Box>
	);
};
