// https://en.wikipedia.org/wiki/Postcodes_in_the_United_Kingdom
// NOTE: not including special cases https://en.wikipedia.org/wiki/Postcodes_in_the_United_Kingdom#Special_cases
export const postCodes = {
	POST_OUTCODE_REGEX: new RegExp("^[a-zA-Z]{1,2}[0-9][a-zA-Z0-9]?$"),
	POST_INCODE_REGEX: new RegExp("^[0-9][a-zA-Z]{2}$"),
};

export const usefulRegex = {
	AT_LEAST_ONE_LETTER: new RegExp("^(?=.*[a-zA-Z])[a-zA-Z,()0-9_ ]*$"),
	/**
	 * MPAN = 13 integers
	 */
	MPAN: new RegExp("^[0-9]{13}$"),
	/**
	 * ELEC_MSN = 3 to 12 alphanumeric characers
	 */
	ELEC_MSN: new RegExp("^[0-9a-zA-Z]{3,12}$"),
	/**
	 * MPRN = 6 to 10 integers
	 */
	MPRN: new RegExp("^[0-9]{6,10}$"),
	/**
	 * Only positive numbers including decimals i.e. 9 and 9.9
	 */
	POSITIVE_NUMBERS_ONLY: new RegExp("^[0-9]+(.[0-9]+)?$"),
	/**
	 * Only positive integers
	 */
	POSITIVE_INTEGERS_ONLY: new RegExp("^[0-9]+$"),
	/**
	 * Only numbers including decimals i.e. 9 and 9.9
	 */
	POSITIVE_NUMBERS_UP_TO_ONE_DECIMAL_PLACE_ONLY: new RegExp(
		"^[0-9]+(.[0-9]{1})?$",
	),
	/**
	 * SSC = 4 integers
	 */
	SSC: new RegExp("^[0-9]{4}$"),
	/**
	 * REGISTER_ID = 1 to 4 alphanumeric characters
	 */
	REGISTER_ID: new RegExp("^[0-9a-zA-Z]{1,4}$"),
};
