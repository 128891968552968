import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Stack,
	TextField,
	Typography,
	alpha,
	useTheme,
} from "@mui/material";

import {
	InboundFlowTestingProcessState,
	InboundFlowTestResponse,
} from "../../models/camunda";

interface IProps {
	process: InboundFlowTestResponse;
}

const highlightTesetResult = (state: string) => {
	if (state === InboundFlowTestingProcessState.TestPassed.valueOf()) {
		return { fontWeight: "bold", color: "GREEN" };
	} else if (
		state === InboundFlowTestingProcessState.NotImplemented.valueOf()
	) {
		return { fontWeight: "bold", color: "GRAY" };
	} else if (state === InboundFlowTestingProcessState.Timeout.valueOf()) {
		return { fontWeight: "bold", color: "GRAY" };
	} else if (state === InboundFlowTestingProcessState.TestFailed.valueOf()) {
		return { fontWeight: "bold", color: "RED" };
	} else {
		return { fontWeight: "bold", color: "BLACK" };
	}
};

const unwrapString = (value?: string) => {
	return value ? value.replaceAll('"', "") : " ";
};

export const InboundFlowTestResult: React.FC<IProps> = (props: IProps) => {
	const theme = useTheme();
	const vars = props.process.variables;
	const comparatorResult = vars.result ? JSON.parse(vars.result) : {};
	const { similarity, errors } = comparatorResult;
	const endEvent = props.process.currentEvent;

	const showErrorField = endEvent != "Test Passed";

	const testResult = props.process.currentEvent
		? props.process.currentEvent
		: " ";
	const similarityRate = similarity ? `${similarity} %` : " ";
	const testErrors = errors ? errors : " ";

	const isBPMNErrorOccurred =
		endEvent == "Error Occurred" ||
		endEvent == "Error Occured" ||
		endEvent == "Not Implemented" ||
		endEvent == "Timeout";
	return (
		<Accordion defaultExpanded>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				aria-controls="inbound-flow-test-result-content"
				id="inbound-flow-test-result-header"
				sx={{
					backgroundColor: alpha(theme.palette.talos.colour.grey.dark, 0.2),
				}}
			>
				<Typography variant="h3" minWidth={900}>
					Test Result
				</Typography>
			</AccordionSummary>
			<AccordionDetails>
				<Stack
					direction="row"
					spacing={10}
					alignItems="flex-end"
					justifyContent="space-around"
					sx={{ mb: 3 }}
				>
					<TextField
						InputProps={{ readOnly: true, disableUnderline: true }}
						fullWidth
						label="Flow:"
						id="inbound-flow-test-result-flow"
						defaultValue={unwrapString(vars.flowType)}
						variant="standard"
					/>
					<TextField
						InputProps={{
							readOnly: true,
							disableUnderline: true,
							sx: highlightTesetResult(endEvent),
						}}
						fullWidth
						label="Test Result:"
						id="inbound-flow-test-result-state"
						defaultValue={testResult}
						variant="standard"
					/>
					{!isBPMNErrorOccurred && (
						<TextField
							InputProps={{ readOnly: true, disableUnderline: true }}
							fullWidth
							label="Similarity Rate:"
							id="inbound-flow-test-result-similarity"
							defaultValue={similarityRate}
							variant="standard"
						/>
					)}
				</Stack>
				{showErrorField && (
					<Stack
						direction="row"
						spacing={10}
						alignItems="flex-end"
						justifyContent="space-around"
					>
						<TextField
							multiline
							InputProps={{
								readOnly: true,
								disableUnderline: true,
								sx: { color: "RED" },
							}}
							fullWidth
							label="Errors:"
							id="inbound-flow-test-result-erros"
							defaultValue={testErrors}
							variant="standard"
						/>
					</Stack>
				)}
			</AccordionDetails>
		</Accordion>
	);
};
