/**
 * Used with formik to extract a specific value for a field
 * @param fieldName the name of the field
 * @param formValues the full form values
 * @returns the value or undefined
 */
export const getValueFromForm = <T extends object>(
	fieldName: string,
	formValues: T,
) => {
	const values = Object.entries(formValues).find((x) => x.includes(fieldName));

	return values && values.length >= 2 ? values[1] : undefined;
};
