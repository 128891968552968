import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Stack,
	TextField,
	Typography,
	alpha,
	useTheme,
} from "@mui/material";

interface IProps {
	message: string;
}

export const InboundFlowTestPUBMessage: React.FC<IProps> = (props: IProps) => {
	const theme = useTheme();
	const message = JSON.parse(props.message);
	return (
		<Accordion defaultExpanded={false}>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				aria-controls="inbound-flow-test-pub-message-content"
				id="inbound-flow-test-pub-message-header"
				sx={{
					backgroundColor: alpha(theme.palette.talos.colour.grey.dark, 0.2),
				}}
			>
				<Typography variant="h3" minWidth={900}>
					PUB
				</Typography>
			</AccordionSummary>
			<AccordionDetails>
				<Stack
					direction="row"
					spacing={10}
					alignItems="flex-end"
					justifyContent="space-around"
				>
					<TextField
						multiline
						InputProps={{
							readOnly: true,
							disableUnderline: true,
						}}
						fullWidth
						label="PUB Message:"
						id="inbound-flow-test-pub-message"
						defaultValue={JSON.stringify(message, null, 2)}
						variant="standard"
					/>
				</Stack>
			</AccordionDetails>
		</Accordion>
	);
};
