import { useContext, useState } from "react";

import {
	Box,
	CircularProgress,
	Divider,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import {
	UseMutationResult,
	useMutation,
	useQuery,
	useQueryClient,
} from "react-query";
import { useNavigate } from "react-router-dom";

import {
	addInboundFlowTestRequest,
	getGeneratedInboundFlow,
} from "../../api/camunda";
import { AuthContext } from "../../auth";
import { AddInboundFlowTestFilterForm } from "../../components/inbound-flows-testing/add-inbound-flow-test-filter-form";
import { AddInboundFlowTestForm } from "../../components/inbound-flows-testing/add-inbound-flow-test-form";
import {
	GeneratedPubMessage,
	InboundFlowFitlerFormValues,
	InboundFlowTestRequest,
	InboundFlowType,
	InternalPubMessage,
} from "../../models/camunda";
import { AlertContext, handleError } from "../../utilities";

export const AddInboundFlowTest: React.FC = () => {
	const theme = useTheme();
	const authContext = useContext(AuthContext);

	const queryClient = useQueryClient();

	const navigate = useNavigate();

	const { setTalosAlert } = useContext(AlertContext);

	const mhhsSpecVersion = "1.7.3";

	const [inboundFlowType, setInboundFlowType] =
		useState<InboundFlowType>("IF-001");

	const onFilterSubmitted = (values: InboundFlowFitlerFormValues) => {
		queryClient.invalidateQueries({ queryKey: ["inbound-flow"] });
		setInboundFlowType(values.inboundFlowType);
	};

	const { data, isLoading } = useQuery({
		refetchOnWindowFocus: false,
		queryKey: ["inbound-flow", authContext, inboundFlowType],
		queryFn: () =>
			getGeneratedInboundFlow(authContext, mhhsSpecVersion, inboundFlowType),
		select: (data: GeneratedPubMessage) => {
			let metadata = data.metadata;
			metadata.rawSourcePath = "/raw/source/path";
			metadata.rawSourceMd5Hash = "938c2cc0dcc05f2b68c4287040cfcf71";
			metadata.recordId = "1";
			const pubMessage: InternalPubMessage = {
				metadata: metadata,
				body: {
					CommonBlock: data.CommonBlock,
					CustomBlock: data.CustomBlock,
				},
			};
			const pub = JSON.stringify(pubMessage, null, 2);
			return pub;
		},
		onError: () => {
			queryClient.setQueriesData(
				["inbound-flow", authContext, inboundFlowType],
				{},
			);
			setTalosAlert({
				message: `${mhhsSpecVersion} : ${inboundFlowType} is not available to generate`,
				severity: "error",
			});
		},
	});

	const inboundFlowsSubmitMutation: UseMutationResult<
		Boolean,
		Error,
		InboundFlowTestRequest
	> = useMutation(
		(value: InboundFlowTestRequest) => {
			return addInboundFlowTestRequest(authContext, value);
		},
		{
			onSuccess: () => {
				setTalosAlert({
					message: `${inboundFlowType} Inbound Flow has been received`,
					severity: "success",
				});
				navigate("/inbound-flow-testing-processes");
			},
			onError: (e: Error) => {
				const errorId = handleError(e);

				setTalosAlert({
					message: `Something went wrong submitting ${inboundFlowType} Inbound Flow form. Please contact IOPS Support, Ticket ID: ${errorId}`,
					severity: "error",
				});

				scrollTo(0, 0);
			},
		},
	);

	return (
		<Box sx={{ width: "760px", margin: "0 auto;" }}>
			<Typography variant="h2" sx={{ mt: 0, mb: 1, textAlign: "center" }}>
				Start new Inbound Flow Testing process
			</Typography>
			<Typography variant="body2">
				Fill in this form to generate random IF-* message and send it to
				internal kafka topic.
			</Typography>
			<Divider sx={{ margin: theme.spacing(3, 0) }} />
			<AddInboundFlowTestFilterForm
				handleSubmit={onFilterSubmitted}
				formData={{ inboundFlowType: "IF-001" }}
			/>
			<Box sx={{ mt: 2 }}>
				{isLoading || !data ? (
					<Stack
						direction="row"
						justifyContent="space-between"
						alignItems="center"
					>
						<CircularProgress sx={{ margin: "auto" }} />
					</Stack>
				) : (
					<>
						<AddInboundFlowTestForm
							inboundFlowSubmitMutation={inboundFlowsSubmitMutation}
							inboundFlowType={inboundFlowType}
							formData={{
								pubMessage: data,
								mhhsSpecVersion: mhhsSpecVersion,
								flowType: inboundFlowType,
							}}
						/>
					</>
				)}
			</Box>
		</Box>
	);
};
