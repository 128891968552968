import { useEffect, useRef, useState } from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Grid,
	Typography,
	alpha,
	useTheme,
} from "@mui/material";
import Viewer from "bpmn-js/lib/Viewer";
import Canvas from "diagram-js/lib/core/Canvas";

interface IProps {
	bpmn?: string;
}

export const CamundaProcessBpmnDigram: React.FC<IProps> = (props: IProps) => {
	const theme = useTheme();
	const containerRef = useRef(undefined);
	const expandBpmnDiagram = false;
	const [bpmnHeight, setBpmnHeight] = useState<number>(700);
	useEffect(() => {
		if (!containerRef.current) return;
		const viewer = new Viewer({
			container: containerRef.current,
		});

		if (!props.bpmn) return;
		viewer
			.importXML(props.bpmn)
			.then(() => {
				const canvas = viewer.get<Canvas>("canvas");
				canvas.zoom("fit-viewport");
				const currentViewbox = canvas.viewbox();
				setBpmnHeight(currentViewbox.inner.height / 1.1);
			})
			.catch(() => {});

		return () => {
			viewer.destroy();
		};
	}, [bpmnHeight, props.bpmn]);

	return (
		<Accordion defaultExpanded={expandBpmnDiagram}>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				aria-controls="process-bpmn-diagram"
				id="process-bpmn-diagram-header"
				sx={{
					backgroundColor: alpha(theme.palette.talos.colour.grey.dark, 0.2),
				}}
			>
				<Typography variant="h3" minWidth={900}>
					Process BMPN
				</Typography>
			</AccordionSummary>
			<AccordionDetails>
				<Grid container justifyContent="center">
					<Box
						height={bpmnHeight}
						width={900}
						display="flex"
						alignItems="center"
						ref={containerRef}
					/>
				</Grid>
			</AccordionDetails>
		</Accordion>
	);
};
