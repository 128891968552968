import { useContext, useState } from "react";

import ReplayOutlinedIcon from "@mui/icons-material/ReplayOutlined";
import {
	Box,
	CircularProgress,
	Grid,
	IconButton,
	Typography,
} from "@mui/material";
import { useQuery } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";

import { getMpanRelationshipProcess } from "../../api/camunda";
import { AuthContext } from "../../auth";
import {
	CamundaProcessDetails,
	CamundaProcessFlowLog,
} from "../../components/camunda-process";
import { CamundaProcessBpmnDigram } from "../../components/camunda-process/camunda-process-bpmn-diagram";
import { CamundaProcessException } from "../../components/camunda-process/camunda-process-exception";
import { Pub020ResponseDetails } from "../../components/mpan-relationship/pub020-details";
import {
	MpanRelationshipProcessState,
	MpanRelationshipProcessPageResponse,
	MpanRelationshipProcessResponse,
	CamundaProcessStatus,
} from "../../models/camunda";
import { AlertContext, handleError } from "../../utilities";
import { MpanRelationshipProcessAction } from "../../components/mpan-relationship/mpan-relationship-process-action";
import { S0020RequestDetails } from "../../components/mpan-relationship/s0020-details";

export const MpanRelationshipProcess: React.FC = () => {
	const navigate = useNavigate();

	const authContext = useContext(AuthContext);

	const location = useLocation();
	const { pathname } = location;
	const processId: number = Number(pathname.split("/").pop());

	const { setTalosAlert } = useContext(AlertContext);

	const [showBpmnSection, setShowBpmnSection] = useState<boolean>(false);

	const [showFlowLogSection, setShowFlowLogSection] = useState<boolean>(false);

	const [showPub020ResponseSection, setShowPub020ResponseSection] =
		useState<boolean>(false);

	const [showExceptionSection, setShowExceptionSection] =
		useState<boolean>(false);

	const [showProcessActionSection, setShowProcessActionSection] =
		useState<boolean>(false);

	const [showS0020RequestSection, setShowS0020RequestSection] =
		useState<boolean>(false);

	const {
		data: process,
		isLoading,
		refetch,
	} = useQuery(
		["mpan-relationship-process", authContext, location],
		() => getMpanRelationshipProcess(authContext, processId),
		{
			onSuccess: (data: MpanRelationshipProcessResponse) => {
				// Exception Section
				const showExceptionSection = !(
					data.variables.exception === undefined ||
					data.variables.exception === null ||
					data.variables.exception === "null"
				);

				// PUB020 Section
				const showPub020ResponseSection =
					!(
						data.variables.inboundPUB020 === undefined ||
						data.variables.inboundPUB020 === null ||
						data.variables.inboundPUB020 === "null"
					) &&
					data.currentEvent !==
						MpanRelationshipProcessState.WaitingPub020.valueOf();

				// S020 Section
				const showS0020RequestSection: boolean = !(
					data.variables.newMeteringPointRelationshipUpdateRequest ===
						undefined ||
					data.variables.newMeteringPointRelationshipUpdateRequest === null ||
					data.variables.newMeteringPointRelationshipUpdateRequest === "null"
				);

				// BPMN Section
				const showBpmnSection = !(
					data.bpmn === undefined ||
					data.bpmn === null ||
					data.bpmn === "null"
				);

				// Flow Log Section
				const showFlowLogSection = data.flowLog.length != 0;

				// Action Section
				const showProcessActionSection =
					data.currentEvent ==
						MpanRelationshipProcessState.ActionRequired.valueOf() &&
					data.state != CamundaProcessStatus.CANCELED.valueOf();

				setShowBpmnSection(showBpmnSection);
				setShowFlowLogSection(showFlowLogSection);
				setShowPub020ResponseSection(showPub020ResponseSection);
				setShowExceptionSection(showExceptionSection);
				setShowProcessActionSection(showProcessActionSection);
				setShowS0020RequestSection(showS0020RequestSection);
			},
			select: (data: MpanRelationshipProcessPageResponse) => {
				if (data.items.length == 0) {
					navigate("/mpan-relationship-processes");
				}
				return data.items[0];
			},
			onError: (e: Error) => {
				const errorId = handleError(e);
				setTalosAlert({
					message: `Something went wrong while fetching MPAN Relationship, please try again later or contact IOPS Support. Ticket ID: ${errorId}`,
					severity: "error",
				});
			},
		},
	);

	return (
		<Box data-cy="mpan-relationship-process-panel">
			<Typography variant="h2" sx={{ mt: 0, mb: 1, textAlign: "center" }}>
				MPAN Relationship Process
			</Typography>
			{isLoading || !process ? (
				<Box>
					<Typography minWidth={900} sx={{ mt: 8, mb: 1, textAlign: "center" }}>
						<CircularProgress />
					</Typography>
				</Box>
			) : (
				<Box>
					<Grid
						container
						direction="row"
						justifyContent="flex-end"
						alignItems="center"
					>
						<IconButton
							aria-label="previous"
							onClick={() => {
								refetch();
							}}
						>
							<ReplayOutlinedIcon />
						</IconButton>
					</Grid>
					<CamundaProcessDetails process={process} />
					{showBpmnSection && <CamundaProcessBpmnDigram bpmn={process.bpmn} />}
					{showFlowLogSection && <CamundaProcessFlowLog process={process} />}
					{showS0020RequestSection && <S0020RequestDetails process={process} />}
					{showPub020ResponseSection && (
						<Pub020ResponseDetails process={process} />
					)}
					{showExceptionSection && (
						<CamundaProcessException
							processState={process.currentEvent}
							exception={process.variables.exception!}
						/>
					)}
					{showProcessActionSection && (
						<MpanRelationshipProcessAction process={process} />
					)}
				</Box>
			)}
		</Box>
	);
};
