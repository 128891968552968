import Keycloak from "keycloak-js";

import { IAuthContext } from "./context";
import { appConfig } from "../utilities";

interface KeycloakUserInfo {
	sub?: string;
	name?: string;
	email?: string;
	email_verified?: boolean;
	given_name?: string;
	family_name?: string;
	preferred_username?: string;
}

export const initializeAuthContext = async (): Promise<IAuthContext> => {
	const keycloak = new Keycloak(appConfig.keycloak.config);
	console.log(appConfig.keycloak.config);

	const authenticated = await keycloak.init(appConfig.keycloak.initOptions);

	if (authenticated) {
		const user: KeycloakUserInfo = await keycloak.loadUserInfo();

		return {
			userName: `${user.name}`,
			email: `${user.email}`,
			provider: keycloak,
			hasRole: (roleName: string) => keycloak.hasRealmRole(roleName),
			hasAnyRole: () => keycloak.realmAccess !== undefined,
		};
	}

	throw new Error("Keycloak failed to authenticate");
};
