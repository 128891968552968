import React, { useContext, useState } from "react";

import { Box, CssBaseline, Toolbar, useTheme } from "@mui/material";
import { Outlet } from "react-router-dom";

import { LeftMenu, AppMessage, TopNav, AppRolesSnackbar } from "../components";
import { AuthContext } from "../auth";

export const Layout: React.FC = () => {
	const [leftMenuOpen, setLeftMenuOpen] = useState(true);
	const { hasAnyRole } = useContext(AuthContext);

	const theme = useTheme();

	function handleMenuClick(): void {
		setLeftMenuOpen((prev) => !prev);
	}

	return (
		<Box sx={{ display: "flex" }}>
			<CssBaseline />

			<TopNav onMenuClick={handleMenuClick} />
			<LeftMenu open={leftMenuOpen} onClose={() => setLeftMenuOpen(false)} />
			<Box component="main" sx={{ flexGrow: 1, p: 3 }}>
				<Toolbar />
				<AppMessage />
				{hasAnyRole() ? (
					<Box sx={{ display: "flex", justifyContent: "center" }}>
						<Box
							sx={{
								backgroundColor: theme.palette.common.white,
								borderRadius: 1,
								padding: theme.spacing(4),
								display: "inline-block",
								margin: theme.spacing(6, 0),
								boxShadow:
									"0px 2px 4px rgba(0, 0, 0, 0.14), 0px 3px 4px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2)",
							}}
						>
							<Outlet />
						</Box>
					</Box>
				) : (
					<AppRolesSnackbar />
				)}
			</Box>
		</Box>
	);
};
