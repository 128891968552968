import { Box } from "@mui/material";
import { Form, Formik } from "formik";
import { UseMutationResult } from "react-query";

import {
	InboundFlowFormValues,
	InboundFlowTestRequest,
	InboundFlowType,
} from "../../models/camunda";
import { TalosButton, TalosTextBox } from "../forms";
import { formValidationSchema } from "./add-inbound-flow-test-form.validation-schema";

interface IProps {
	inboundFlowSubmitMutation: UseMutationResult<
		Boolean,
		Error,
		InboundFlowTestRequest
	>;
	formData: InboundFlowFormValues;
	inboundFlowType: InboundFlowType;
}

export const AddInboundFlowTestForm: React.FC<IProps> = ({
	inboundFlowSubmitMutation,
	inboundFlowType,
	formData,
}) => {
	const handleOnSubmit = (values: InboundFlowFormValues) => {
		inboundFlowSubmitMutation.mutate(mapFormToValues(values));
	};

	return (
		<Formik
			enableReinitialize
			onSubmit={handleOnSubmit}
			initialValues={formData}
			validationSchema={formValidationSchema}
			validateOnChange={true}
			validateOnMount={true}
		>
			{(form) => (
				<Form>
					<Box className="form-wrapper">
						<Box className="form-column">
							<TalosTextBox
								fieldName="pubMessage"
								label={`${inboundFlowType} Inbound Message *`}
								form={form}
								multiline={true}
								multilineMaxRows={15}
								autoFocus={true}
								sx={{ my: 1, width: "100%" }}
							/>
							<TalosButton
								fieldName="form_submit"
								form={form}
								buttonText="Submit"
								loading={inboundFlowSubmitMutation.isLoading}
								enableIfNotDirty={true}
							/>
						</Box>
					</Box>
				</Form>
			)}
		</Formik>
	);
};

const mapFormToValues = (
	values: InboundFlowFormValues,
): InboundFlowTestRequest => {
	return { ifMessage: values.pubMessage };
};
