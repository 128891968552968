import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Stack,
	TextField,
	Typography,
	alpha,
	useTheme,
} from "@mui/material";

interface IProps {
	comparatorResult: string;
}

export const InboundFlowTestIfToPubDiff: React.FC<IProps> = (props: IProps) => {
	const theme = useTheme();
	const testResult = JSON.parse(props.comparatorResult);
	const { patch } = testResult;
	return (
		<Accordion defaultExpanded={false}>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				aria-controls="inbound-flow-test-details-content"
				id="inbound-flow-test-details-header"
				sx={{
					backgroundColor: alpha(theme.palette.talos.colour.grey.dark, 0.2),
				}}
			>
				<Typography variant="h3" minWidth={900}>
					Diff
				</Typography>
			</AccordionSummary>
			<AccordionDetails>
				<Stack
					direction="row"
					spacing={10}
					alignItems="flex-end"
					justifyContent="space-around"
				>
					<TextField
						multiline
						InputProps={{
							readOnly: true,
							disableUnderline: true,
						}}
						fullWidth
						label="Patch:"
						id="inbound-flow-test-result-patch"
						defaultValue={patch}
						variant="standard"
					/>
				</Stack>
			</AccordionDetails>
		</Accordion>
	);
};
