import { LoadingButton } from "@mui/lab";
import { Box } from "@mui/material";
import { Form, Formik } from "formik";
import { UseMutationResult } from "react-query";

interface IProps {
	closeProcessMutation: UseMutationResult<Boolean, Error>;
}

export const CloseProcessForm: React.FC<IProps> = ({
	closeProcessMutation: closeProcessMutation,
}) => {
	const handleOnSubmit = () => {
		closeProcessMutation.mutate({});
	};

	return (
		<Formik onSubmit={handleOnSubmit} initialValues={{}} isInitialValid={true}>
			{() => (
				<Form>
					<Box>
						<LoadingButton
							type="submit"
							variant="contained"
							color="error"
							loading={closeProcessMutation.isLoading}
						>
							{"Close"}
						</LoadingButton>
					</Box>
				</Form>
			)}
		</Formik>
	);
};
