interface Event {
	title: string;
	date: string;
	notes: string;
	bunting: boolean;
}

const getBankHolidays = (locale: string = "england-and-wales"): string[] => {
	const request = new XMLHttpRequest();
	request.open("GET", "https://www.gov.uk/bank-holidays.json", false);
	request.send(null);
	const res = JSON.parse(request.responseText);

	return res[locale]["events"].map((x: Event) => x.date);
};

let bankHolidays: string[] = [];

if (bankHolidays.length === 0) {
	bankHolidays = getBankHolidays();
}

export default bankHolidays;
