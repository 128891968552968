import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Stack,
	TextField,
	Typography,
	alpha,
	useTheme,
} from "@mui/material";

import {
	MpanRelationshipProcessState,
	Pub,
	PubB090List,
	MpanRelationshipProcessResponse,
} from "../../models/camunda";

interface IProps {
	process: MpanRelationshipProcessResponse;
}

const unwrapString = (value?: string) => {
	return value ? value.replaceAll('"', "") : "";
};

export const Pub020ResponseDetails: React.FC<IProps> = (props: IProps) => {
	const theme = useTheme();
	const expandResponseDetails =
		props.process.currentEvent ==
		MpanRelationshipProcessState.ActionRequired.valueOf();
	const inboundPUB020: string = props.process.variables.inboundPUB020!;
	const pub: Pub = JSON.parse(inboundPUB020);

	const hideResponseMessages =
		pub.CommonBlock?.R0?.responseMessages == undefined ||
		pub.CommonBlock?.R0?.responseMessages.length == 0;

	let responseMessages = "";
	if (pub.CommonBlock?.R0?.responseMessages == undefined) {
		responseMessages = "";
	} else {
		responseMessages = pub.CommonBlock?.R0?.responseMessages
			.map((m: string) => m + "\n")
			.join("");
	}

	const hideAdditionalInfo =
		pub.CustomBlock?.B091?.additionalInformation == undefined ||
		pub.CustomBlock?.B091?.additionalInformation.length == 0;

	const hideSubsidiaryMPANs =
		pub.CustomBlock?.B090List == undefined ||
		pub.CustomBlock?.B090List.length == 0;

	let subsidiaryMPANs = "";
	if (pub.CustomBlock?.B090List == undefined) {
		subsidiaryMPANs = "";
	} else {
		subsidiaryMPANs = pub.CustomBlock?.B090List.map(
			(b090: PubB090List) => b090.subsidiaryMPAN + "\n",
		).join("");
	}

	return (
		<Accordion defaultExpanded={expandResponseDetails}>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				aria-controls="pub020-response-details-content"
				id="pub020-response-details-header"
				sx={{
					backgroundColor: alpha(theme.palette.talos.colour.grey.dark, 0.2),
				}}
			>
				<Typography variant="h3" minWidth={900}>
					PUB020 Details
				</Typography>
			</AccordionSummary>
			<AccordionDetails>
				<Stack
					direction="row"
					spacing={10}
					alignItems="flex-end"
					justifyContent="space-around"
					sx={{ mb: 3 }}
				>
					<TextField
						InputProps={{ readOnly: true, disableUnderline: true }}
						fullWidth
						label="Environment:"
						id="process-response-details-if-id"
						defaultValue={unwrapString(pub.CommonBlock?.S1?.environmentTag)}
						variant="standard"
					/>
					<TextField
						InputProps={{ readOnly: true, disableUnderline: true }}
						fullWidth
						label="Sender Role:"
						id="process-response-details-id"
						defaultValue={unwrapString(pub.CommonBlock?.S1?.senderRoleID)}
						variant="standard"
					/>
				</Stack>
				<Stack
					direction="row"
					spacing={10}
					alignItems="flex-end"
					justifyContent="space-around"
					sx={{ mb: 3 }}
				>
					<TextField
						InputProps={{ readOnly: true, disableUnderline: true }}
						fullWidth
						label="Interface ID:"
						id="process-response-details-if-id"
						defaultValue={unwrapString(pub.CommonBlock?.S0?.interfaceID)}
						variant="standard"
					/>
					<TextField
						InputProps={{ readOnly: true, disableUnderline: true }}
						fullWidth
						label="Event Code:"
						id="process-response-details-eventCode"
						defaultValue={unwrapString(pub.CommonBlock?.S0?.eventCode)}
						variant="standard"
					/>
				</Stack>
				<Stack
					direction="row"
					spacing={10}
					alignItems="flex-end"
					justifyContent="space-around"
					sx={{ mb: 3 }}
				>
					<TextField
						InputProps={{ readOnly: true, disableUnderline: true }}
						fullWidth
						label="Principal MPAN:"
						id="process-response-details-principal-mpan"
						defaultValue={unwrapString(pub.CommonBlock?.M1?.principalMPAN)}
						variant="standard"
					/>
					<TextField
						InputProps={{ readOnly: true, disableUnderline: true }}
						fullWidth
						label="Response Code:"
						id="process-response-details-response-code"
						defaultValue={unwrapString(pub.CommonBlock?.R0?.responseCode)}
						variant="standard"
					/>
				</Stack>
				{!hideResponseMessages && (
					<Stack
						direction="row"
						spacing={10}
						alignItems="flex-end"
						justifyContent="space-around"
					>
						<TextField
							multiline
							InputProps={{ readOnly: true, disableUnderline: true }}
							fullWidth
							label="Response Messages:"
							id="process-response-details-response-messages"
							defaultValue={responseMessages}
							variant="standard"
						/>
					</Stack>
				)}
				{!hideSubsidiaryMPANs && (
					<Stack
						direction="row"
						spacing={10}
						alignItems="flex-end"
						justifyContent="space-around"
					>
						<TextField
							multiline
							InputProps={{ readOnly: true, disableUnderline: true }}
							fullWidth
							label="Subsidiary MPANs:"
							id="process-response-details-subsidiary-mpans"
							defaultValue={subsidiaryMPANs}
							variant="standard"
						/>
					</Stack>
				)}
				{!hideAdditionalInfo && (
					<Stack
						direction="row"
						spacing={10}
						alignItems="flex-end"
						justifyContent="space-around"
					>
						<TextField
							multiline
							InputProps={{ readOnly: true, disableUnderline: true }}
							fullWidth
							label="Additional Information:"
							id="process-response-details-additional-information"
							defaultValue={unwrapString(
								pub.CustomBlock?.B091?.additionalInformation,
							)}
							variant="standard"
						/>
					</Stack>
				)}
			</AccordionDetails>
		</Accordion>
	);
};
