import React, { useContext } from "react";

import { Box, Typography, useTheme } from "@mui/material";
import BugReportRoundedIcon from "@mui/icons-material/BugReportRounded";
import LinkRoundedIcon from "@mui/icons-material/LinkRounded";

import { AuthContext } from "../auth";
import { ROLES } from "../utilities";
import { ActionCard } from "../components";

export const Home: React.FC = () => {
	const { hasRole } = useContext(AuthContext);
	const theme = useTheme();

	return (
		<Box data-cy="dashboard" maxWidth={800}>
			{hasRole(ROLES.INBOUND_TESTING) ? (
				<Box>
					<Typography variant="h2" gutterBottom data-cy="dashboard-msd-panel">
						Inbound Flow Testing
					</Typography>

					<Box className="action-wrapper">
						<ActionCard
							path="/inbound-flow-testing-processes/start"
							icon={<BugReportRoundedIcon fontSize="large" />}
							backgroundColor={theme.palette.primary.main}
							title="Start Test"
							text="
              The form to start new Inbound Flow test."
						/>
					</Box>
				</Box>
			) : null}

			{hasRole(ROLES.S0020) ? (
				<Box sx={{ mt: 5 }}>
					<Typography variant="h2" gutterBottom data-cy="dashboard-msd-panel">
						Change MPAN Relationship
					</Typography>

					<Box className="action-wrapper">
						<ActionCard
							path="/mpan-relationship-processes/add"
							icon={<LinkRoundedIcon fontSize="large" />}
							backgroundColor={theme.palette.primary.main}
							title="Add Request"
							text="The form for sending MPAN relationship requests."
						/>
					</Box>
				</Box>
			) : null}
		</Box>
	);
};
