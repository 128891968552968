import * as Yup from "yup";

export const formValidationSchema = Yup.object().shape({
	pubMessage: Yup.string()
		.required("IF-* Message required")
		.test("json", "Invalid JSON format", (value) => {
			try {
				JSON.parse(value);
				return true;
			} catch (error) {
				return false;
			}
		}),
});
