import React from "react";

import Keycloak, { KeycloakProfile } from "keycloak-js";

export interface IAuthContext {
	userName: string;
	email: string;
	provider?: Keycloak;
	hasRole: (roleName: string) => boolean;
	hasAnyRole: () => boolean;
}

export const getContext = (
	keycloak: Keycloak,
	profile: KeycloakProfile,
): IAuthContext => {
	return {
		userName: `${profile.firstName} ${profile.lastName}`,
		email: `${profile.email}`,
		provider: keycloak,
		hasRole: () => false,
		hasAnyRole: () => false,
	};
};

export const AuthContext = React.createContext<IAuthContext>({
	userName: "",
	email: "",
	provider: undefined,
	hasRole: () => false,
	hasAnyRole: () => false,
});
