import * as Yup from "yup";

import { usefulRegex } from "../../utilities";

export const formValidationSchema = Yup.object().shape({
	principalMpan: Yup.string()
		.matches(usefulRegex.MPAN, "MPAN must be a 13 digit integer")
		.required("MPAN is required"),
	relationshipType: Yup.string().required(),
	relationshipAction: Yup.string().required(),
	mpanAction: Yup.string().required(),
	exportMpan: Yup.string().when("relationshipType", {
		is: "L",
		then: (schema) =>
			schema
				.matches(usefulRegex.MPAN, "MPAN must be a 13 digit integer")
				.notOneOf(
					[Yup.ref("principalMpan")],
					"REG1059 - MPAN(s) cannot be duplicated within same message",
				)
				.required("MPAN is required"),
	}),
	secondaryMpan: Yup.string().when("relationshipType", {
		is: "R",
		then: (schema) =>
			schema
				.matches(usefulRegex.MPAN, "MPAN must be a 13 digit integer")
				.notOneOf(
					[Yup.ref("principalMpan")],
					"REG1059 - MPAN(s) cannot be duplicated within same message",
				)
				.required("MPAN is required"),
	}),
});
