import React, { useEffect, useRef, useState } from "react";

import { ErrorBoundary } from "@sentry/react";
import "./App.css";
import { QueryClient, QueryClientProvider } from "react-query";

import { AuthContext, IAuthContext, initializeAuthContext } from "./auth";
import { Loading } from "./pages";
import { AppRouter } from "./routes";
import {
	AlertContext,
	defaultAlertState,
	handleError,
	ITalosAlert,
} from "./utilities";

const queryClient = new QueryClient();

export const App: React.FC = () => {
	const [authContext, setAuthContext] = useState<IAuthContext | undefined>(
		undefined,
	);
	const [talosAlert, setAppAlert] = useState<ITalosAlert>(defaultAlertState);

	const alertContext = {
		talosAlert: talosAlert,
		setTalosAlert: setAppAlert,
	};

	const authRef = useRef(false);

	useEffect(() => {
		if (!authRef.current) {
			authRef.current = true;

			initializeAuthContext()
				.then((ctx) => {
					setAuthContext(ctx);
				})
				.catch(handleError);
		}
	});

	return authContext ? (
		<ErrorBoundary>
			<AuthContext.Provider value={authContext}>
				<AlertContext.Provider value={alertContext}>
					<QueryClientProvider client={queryClient}>
						<AppRouter />
					</QueryClientProvider>
				</AlertContext.Provider>
			</AuthContext.Provider>
		</ErrorBoundary>
	) : (
		<Loading />
	);
};
