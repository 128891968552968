import * as Sentry from "@sentry/react";

import { appConfig } from "./configuration";

/**
 * Abstraction over Sentry framework
 *
 *  @param error Error to log
 * @param severity
 *
 * @returns Sentry generated eventId
 */
export const handleError = (
	error: any,
	severity: Sentry.SeverityLevel = "error",
) => {
	let errorId = "";

	if (appConfig.isDevelopment) {
		console.log(error);
	} else {
		errorId = Sentry.captureException(error, { level: severity });
	}

	return errorId;
};
