import React, { useContext } from "react";

import ExitToAppRoundedIcon from "@mui/icons-material/ExitToAppRounded";
import PersonOutlineRoundedIcon from "@mui/icons-material/PersonOutlineRounded";
import {
	AppBar,
	Box,
	Divider,
	IconButton,
	Link,
	ListItemIcon,
	Menu,
	MenuItem,
	Toolbar,
	Typography,
	useTheme,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import ListItemText from "@mui/material/ListItemText";

import { TalosBreadCrumbs } from ".";
import { AuthContext } from "../../auth";
import { handleError } from "../../utilities";

interface IProps {
	onMenuClick: () => void;
}

export const TopNav: React.FC<IProps> = (props: IProps) => {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const { hasAnyRole } = useContext(AuthContext);

	const theme = useTheme();
	const open = Boolean(anchorEl);

	return (
		<AuthContext.Consumer>
			{(authContext) => (
				<>
					<AppBar
						position="fixed"
						elevation={3}
						sx={{
							zIndex: theme.zIndex.drawer + 1,
							backgroundColor: theme.palette.common.white,
							color: theme.palette.talos.semantic.message.black,
							transition: theme.transitions.create(["width", "margin"], {
								easing: theme.transitions.easing.sharp,
								duration: theme.transitions.duration.leavingScreen,
							}),
						}}
					>
						<Toolbar disableGutters>
							<Box
								sx={{
									display: "flex",
									alignItems: "center",
									flexShrink: 1,
									minWidth: "140px",
									justifyContent: "space-between",
									width: "100%",
								}}
							>
								<Box
									sx={{
										display: "flex",
									}}
								>
									{hasAnyRole() ? (
										<IconButton
											color="inherit"
											aria-label="open drawer"
											data-cy="open-drawer-button"
											onClick={() => props.onMenuClick()}
										>
											<MenuIcon />
										</IconButton>
									) : null}
									<Link
										href="/"
										sx={{
											cursor: "pointer",
											color: theme.palette.talos.semantic.message.black,
											textDecoration: "none",
										}}
									>
										<Typography
											variant="h1"
											sx={{ padding: theme.spacing(1.5, 0) }}
											data-cy="talos-name-header"
										>
											MHHS
										</Typography>
									</Link>

									<TalosBreadCrumbs />
								</Box>
								<Box
									sx={{
										marginRight: theme.spacing(2),
									}}
								>
									<IconButton
										color="inherit"
										aria-label="open drawer"
										onClick={(event) => setAnchorEl(event.currentTarget)}
										data-cy="account-menu-button"
									>
										<AccountCircleRoundedIcon
											sx={{
												width: theme.spacing(6),
												height: theme.spacing(6),
											}}
										/>
									</IconButton>
								</Box>
							</Box>
						</Toolbar>
					</AppBar>

					<Menu
						id="basic-menu"
						anchorEl={anchorEl}
						open={open}
						onClose={() => setAnchorEl(null)}
						data-cy="account-menu"
						MenuListProps={{
							"aria-labelledby": "basic-button",
						}}
					>
						<MenuItem sx={{ justifyContent: "center" }}>
							<ListItemIcon>
								<PersonOutlineRoundedIcon fontSize="small" />
							</ListItemIcon>
							<ListItemText>{authContext.email}</ListItemText>
						</MenuItem>
						<Divider />
						<MenuItem
							onClick={() =>
								authContext.provider
									? authContext.provider.logout()
									: handleError("Not authenticated")
							}
							data-cy="logout-button"
							sx={{ justifyContent: "center" }}
						>
							<ListItemIcon>
								<ExitToAppRoundedIcon fontSize="small" />
							</ListItemIcon>
							<ListItemText>
								<strong>Log Out</strong>
							</ListItemText>
						</MenuItem>
					</Menu>
				</>
			)}
		</AuthContext.Consumer>
	);
};
